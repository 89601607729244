import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Nosotros" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="card-content-image">
          <figure>
            <GatsbyImage
              image={data.danielaCastro.childImageSharp.gatsbyImageData}
              alt="foto de Daniela Castro"
              placeholder="blurred"
              className="card-image"
              imgStyle={{ borderRadius: "50%" }}
            />
            {/* <figcaption
                style={{
                  padding: "8px",
                }}
              >
                Daniela Castro
              </figcaption> */}
          </figure>
        </div>
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Único, rentable y sustentable. DACS es la solución que estabas esperando.
          </h2>
        </div>
        <div className="card">
          <div className="card-content post-content-body">
            <p>
              Somos un estudio de arquitectura en el que creemos que, a través del diseño, podemos
              lograr verdadera sostenibilidad al reducir la huella de carbono de los usuarios de
              nuestros edificios e incrementar el retorno de inversión de los desarrolladores
              simplemente por el hecho de ser un edificio <b> eficiente y funcional.</b>
            </p>
            <p>
              Trabajando de la mano con nuestros clientes, buscamos el bienestar de los usuarios del
              edificio y la reducción de los consumos de agua, energía y materiales, lo cual se toma
              en cuenta desde la selección del terreno y el diseño, hasta la construcción y puesta
              en marcha de los edificios.
            </p>
            <p>
              Te invitamos a revisar <a href="/tags">nuestros proyectos</a> para que conozcas más
              acerca de nosotros. <br />
              <strong>- Daniela Castro</strong>
            </p>
            <br />
            <br />
            <h2>Daniela Castro</h2>
            <p>
              Es una arquitecta egresada con mención honorífica de la UNAM en 2009 y con estudios de
              intercambio en la ABK Stuttgart de Alemania. Esta especializada en diseño y desarrollo
              sustentable con estudios de posgrado en Reino Unido, España y México.
            </p>
            <p>
              Habiendo ganado varios concursos de diseño en su trayectoria, sus proyectos incluyen
              hoteles, corporativos, centros comerciales, edificios residenciales y retrofit entre
              otros.
            </p>
            <p>
              Antes de fundar DACS, Daniela participó en el diseño de proyectos en despachos como
              Hofmeister Architekten y Edmonds International y posteriormente, en desarrolladoras
              como Pulso Inmobiliario y MIRA, tuvo a su cargo la dirección de equipos de proyectos
              de gran escala y usos mixtos con equipos interdisciplinarios mexicanos y extranjeros.
            </p>
            <p>
              En 2012 estudió una maestría en Diseño Sustentable Avanzado en The University of
              Edinburgh de la cual se graduó con honores. Desde el 2010 cuenta con la credencial de
              LEED AP otorgada por el U.S. Green Building Council, periodo durante el cual ha sido
              responsable por la certificación LEED de 8 proyectos de gran escala en México.
              Adicionalmente cuenta con la credencial de Edge Expert desde 2018 y busca incorporar
              sus conocimientos en diseño sustentable en todos los proyectos que se involucra.
            </p>
            <p>
              En 2016 funda DACS con el objetivo de ofrecer servicios de diseño arquitectónico y
              consultoría sustentable para proyectos de todas las escalas manteniendo como prioridad
              la sustentabilidad de los mismos desde el punto de vista ambiental, económico y
              social.
            </p>
          </div>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    danielaCastro: file(relativePath: { eq: "danielaCastro.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => <AboutPage location={props.location} data={data} {...props} />}
  />
);
